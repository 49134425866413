import { ActionIcon, Badge, Group, Text } from "@mantine/core";
import { IconEdit } from "@tabler/icons-react";
import { MassCommunication, OrgPermissions, tu } from "beitary-shared";
import { BDataTable, DeleteConfirmationModal } from "components";
import { useDBServices } from "hooks/useDBService/useDBService";
import { BCan } from "permissions";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { BDataTableColumn } from "schemas-types";

type MassCommunicationsTableItem = MassCommunication & {
  actions?: undefined;
};

export interface MassCommunicationTableProps {
  massCommunications: undefined | null | MassCommunication[];
}

export const MassCommunicationTable = ({
  massCommunications,
}: MassCommunicationTableProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const db = useDBServices().clientCommunication.massCommunications;

  const tableItems: MassCommunicationsTableItem[] = (
    massCommunications ?? []
  ).map((item) => ({
    ...item,
  }));

  const columns: BDataTableColumn<MassCommunicationsTableItem>[] = [
    {
      accessor: "status",
      title: <Text>{t("STATUS")}</Text>,
      sortable: true,
      render: ({ status }) => (
        <Badge
          color={
            status === "SENT"
              ? "teal.4"
              : status === "PENDING"
              ? "orange.4"
              : status === "SENDING"
              ? "blue.4"
              : "grey.0"
          }
        >
          {t(status)}
        </Badge>
      ),
    },
    {
      accessor: "messageType",
      title: <Text>{t("MESSAGE_TYPE")}</Text>,
      sortable: true,
    },
    {
      accessor: "title",
      title: <Text>{t("TITLE")}</Text>,
      sortable: true,
    },
    {
      accessor: "createdAt",
      title: <Text>{t("DATE_CREATED")}</Text>,
      sortable: true,
      render: ({ createdAt }) => (
        <Text>{tu.getDateAndTimeString(createdAt)}</Text>
      ),
    },
    {
      accessor: "audience",
      title: <Text>{t("RECIPIENTS")}</Text>,
      render: ({ audience }) => <Text>{audience.length}</Text>,
    },
    // {
    //   accessor: "recipients",
    //   title: <Text>{t("SENT")}</Text>,
    //   render: ({ recipients }) => <Text>{recipients.length}</Text>,
    // },
    {
      accessor: "sentOn",
      title: <Text>{t("SENT_ON")}</Text>,
      sortable: true,
      render: ({ sentOn }) => (
        <Text>{sentOn ? tu.getDateAndTimeString(sentOn) : ""}</Text>
      ),
    },

    {
      accessor: "actions",
      title: "",
      width: 140,
      render: (item) => (
        <BCan I="do" a={OrgPermissions.CLIENT_COMMUNICATION}>
          <Group position="right">
            <ActionIcon
              onClick={() =>
                navigate(`edit-${item.messageType.toLowerCase()}/${item.id}`)
              }
            >
              <IconEdit size={18} />
            </ActionIcon>

            <DeleteConfirmationModal
              f={async () => db.deleteMassCommunication(item.id)}
              item={{ id: item.id, name: item.title }}
            />
          </Group>
        </BCan>
      ),
    },
  ];

  return (
    <BDataTable
      data={tableItems}
      columns={columns}
      defaultSortKey={"createdAt"}
    />
  );
};
